import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const App = lazy(() => import('sections/hero/App.js'));
const Apps = lazy(() => import('sections/about/Apps.js'));
const PlataformasApp = lazy(() => import('sections/services/PlataformasApp.js'));
const BoxColApps = lazy(() => import('sections/hero/BoxColApps.js'));
const AppPasos = lazy(() => import('sections/services/AppPasos.js'));
const TextApp = lazy(() => import('sections/services/TextApp.js'));
const ProsPlataformaApp = lazy(() => import('sections/services/ProsPlataformaApp.js'));
const NativavsMultiplataforma = lazy(() => import('sections/services/NativavsMultiplataforma.js'));
const ServicesFrameworks = lazy(() => import('sections/services/ServicesFrameworks.js'));
const AppMultiplataforma = lazy(() => import('sections/hero/AppMultiplataforma.js'));
const AppEmpresas = lazy(() => import('sections/hero/AppEmpresas.js'));
const Stack = lazy(() => import('sections/about/Stack.js'));
const BotonAPP = lazy(() => import('sections/services/BotonAPP.js'));
const DeveloperWeb = lazy(() => import('sections/about/DeveloperWeb.js'));
const DisenoWeb = lazy(() => import('sections/about/DisenoWeb.js'));
const Hola = lazy(() => import('sections/hero/Hola.js'));
const Conecta = lazy(() => import('sections/hero/Conecta.js'));
const OffShore = lazy(() => import('sections/about/OffShore.js'));
const TestimonialsThree = lazy(() => import('sections/testimonials/TestimonialsThree.js'));
const ClientsOne = lazy(() => import('sections/clients/ClientsOne.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));


class Index extends React.Component {

  render() {

    // Datos estructurados para preguntas y respuestas
    const PasoaPaso = {
      "@context": "https://schema.org",
  "@type": "HowTo",
  "name": "Proceso de Desarrollo de una Aplicación",
  "description": "Un paso a paso sobre cómo se desarrolla una aplicación móvil.",
  "step": [
    {
      "@type": "HowToStep",
      "name": "1- Su Idea",
      "itemListElement": [
        {
          "@type": "HowToDirection",
          "text": "Análisis de los requerimientos"
        },
        {
          "@type": "HowToDirection",
          "text": "Arquitectura - Nativa o Multiplataforma"
        },
        {
          "@type": "HowToDirection",
          "text": "Análisis de seguridad"
        }
      ]
    },
    {
      "@type": "HowToStep",
      "name": "2- Diseño",
      "itemListElement": [
        {
          "@type": "HowToDirection",
          "text": "Diseño UI"
        },
        {
          "@type": "HowToDirection",
          "text": "Diseño UX"
        },
        {
          "@type": "HowToDirection",
          "text": "Wireframes"
        },
        {
          "@type": "HowToDirection",
          "text": "Testing"
        }
      ]
    },
    {
      "@type": "HowToStep",
      "name": "3- Desarrollando la APP",
      "itemListElement": [
        {
          "@type": "HowToDirection",
          "text": "Diseño del Front-end"
        },
        {
          "@type": "HowToDirection",
          "text": "Desarrollo del Back-end"
        },
        {
          "@type": "HowToDirection",
          "text": "Testing & QA"
        }
      ]
    },
    {
      "@type": "HowToStep",
      "name": "4- Disfruta la APP",
      "itemListElement": [
        {
          "@type": "HowToDirection",
          "text": "Implementación en Play Store y Apple Store"
        },
        {
          "@type": "HowToDirection",
          "text": "Lanzamiento del proyecto"
        },
        {
          "@type": "HowToDirection",
          "text": "Soporte post-servicio"
        }
      ]
    }
  ]
    }


    

    return (
      <div>
        <Helmet>
          <title>Desarrollo de Apps Móviles en Colombia - Pereira - Bogotá</title>
          <meta name="description" content="Descubre nuestro servicio de desarrollo de apps en Colombia. Creamos aplicaciones móviles para celular innovadoras y personalizadas para impulsar tu negocio." />
          <script type="application/ld+json">
            {JSON.stringify(PasoaPaso)}
          </script>
        </Helmet>
        <Layout>
          <App />
          <Apps />
          <PlataformasApp />
          <BoxColApps />
          <AppPasos />
          <Hola />
          <AppMultiplataforma />
          <ServicesFrameworks />
          <AppEmpresas />
          <Stack />
          <TextApp />
          <ProsPlataformaApp />
          <NativavsMultiplataforma />
          <Conecta />           
          <DeveloperWeb />          
          <DisenoWeb />                   
          <OffShore />          
          <TestimonialsThree />          
          <ClientsOne />
          <Llamenos />
          <BotonAPP />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`